<template>
    <div id="content" class="elabel" :class="[$mq]">
        <template v-if="preview">
            <div class="full-preview">
                <div class="close-preview" @click="fnClosePreview()"></div>
                <div class="preview-container">
                    <div class="gallery-box">
                        <div class="main-image" :class="{ label: selectedPreview == 'label' }">
                            <Preview :data="preview" :maximized="true" v-if="selectedPreview == 'label'" ref="previewComp"></Preview>
                            <img :src="selectedPreview" v-else />
                        </div>
                        <div class="thumb-img" v-if="photosOfLabel && photosOfLabel.length > 0 && isSummaryPhoto">
                            <div class="images-boxes label" @click="selectPreview('label')" :class="{ selected: 'label' == selectedPreview }"></div>
                            <div class="images-boxes" v-for="photo in photosOfLabel" @click="selectPreview(photo)" :class="{ selected: photo == selectedPreview }">
                                <img :src="photo" />
                            </div>
                        </div>
                    </div>
                    <div class="summary-box">
                        <div class="info-summary">
                            <div class="text-box" :class="{ expand: showMore }">
                                <p class="date">{{ $t('label.print.print_date') }}: {{ print_date }}</p>
                                <p v-if="createdBy" class="date">{{ $t('label.preview.created_by') }}: {{ createdBy }}</p>
                                <p class="date" v-for="summary in selectedLabel.summary" v-if="summary && summary.keyname && summary.keyname != 'photo'">{{ summary.name }}: {{ summary.value }}</p>
                            </div>
                            <div class="show-more-container">
                                <span @click="showMoreOrLess" v-if="$mq.includes(['portrait'])" class="show-more-less">
                                    {{ showMore ? 'Ver menos' : 'Ver más' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <header class="header-container">
                <div class="traceability-selector">
                    <Tabs :tabOneText="$t('label.preview.expiration_date')" :tabTwoText="$t('label.preview.print_date')" @switchClick="switchFilter" :selected="filterBy"></Tabs>
                </div>
                <div class="search" :class="{ selected: showFilters }" @click="switchShowFilters()"></div>
            </header>
            <div class="calendar-navigation-container" v-if="!showFilters">
                <calendar-navigation @calendarOpen="calendarState" @select="changeDateCalendar" :selected_date="formatDateForCalendarNavigation()" :future_days="1" :past_days="2"></calendar-navigation>
            </div>
            <CollapseTransition>
                <div class="filters" v-if="showFilters">
                    <div class="advance">
                        <select class="item date" v-model="filters.date" @change="changeDate()">
                            <option v-for="(value, key) in dates" :value="key" :key="key">{{ value }}</option>
                        </select>
                        <!-- show datepicker if filters.date is custom -->
                        <input v-if="filters.date == 'custom'" type="date" class="date-input" :class="{ error: error.customDate }" v-model="customDate" />

                        <select class="item state" v-model="filters.state">
                            <option value="">{{ $t('label.states.all') }}</option>
                            <option v-for="state in states" :value="state.id" :key="state.id">{{ state.name }}</option>
                        </select>
                        <select class="item category" v-model="filters.category">
                            <option value="">{{ $t('label.categories.all') }}</option>
                            <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
                        </select>
                        <div class="item product">
                            <input type="text" v-model="filters.product" :placeholder="$t('label.search_by')" />
                            <div v-if="filters.product != ''" class="close" @click="cleanSearch()"></div>
                        </div>
                        <div class="item bttn" :class="{ disabled: !filterChanges }" @click="search()">{{ $t('menu_aux.action.search') }}</div>
                    </div>
                </div>
            </CollapseTransition>
            <div :key="key" class="list" ref="list" :class="[{ expanded: expanded, empty: results == 0 || offline }, { 'filters-opened': showFilters }, { calendarOpened: calendarIsVisible }]">
                <template v-if="offline">
                    <EmptyPage :title="$t('empty_pages.connection_description')" :description="$t('empty_pages.connection_title')" class="error"></EmptyPage>
                </template>
                <template v-else>
                    <template v-if="results == 0">
                        <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.label_description')"></EmptyPage>
                    </template>
                    <template v-else>
                        <SingleLabel v-for="(label, key) in labels" :key="key" :label="label" @click.native="previewLabel(label)" :showBy="showBy" type="preview"></SingleLabel>
                        <div class="pagination">
                            <div v-if="page > 1" class="item prev" @click="page_prev()"></div>
                            <div v-if="num_items * page < results" class="item next" @click="page_next()"></div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Tabs from '../components/domain/ui/Tabs.vue'
import i18n from '@/i18n'
import Preview from '@/components/domain/label/preview.vue'
import SingleLabel from '@/components/domain/label/singleLabel.vue'
import EmptyPage from '../components/layout/EmptyPage.vue'
import Label from './Label.vue'
import CalendarNavigation from '@/components/layout/CalendarNavigation.vue'
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
    name: 'DigitalLabel',
    components: { Preview, SingleLabel, EmptyPage, Label, CalendarNavigation, Tabs },
    data() {
        return {
            calendarIsVisible: false,
            date: 'today',
            filters: {
                date: 'today',
                state: '',
                category: '',
                product: ''
            },
            dates: {
                today: i18n.t('label.filter.today'),
                yesterday: i18n.t('label.filter.yesterday'),
                week: i18n.t('label.filter.week'),
                month: i18n.t('label.filter.month'),
                quarter: i18n.t('label.filter.quarter'),
                custom: i18n.t('label.filter.select_day')
                // custom: i18n.t('label.filter.custom')
            },
            expanded: false,
            preview: false,
            print_date: false,
            offline: false,
            num_items: 20,
            page: 1,
            showFilters: false,
            compareFilters: {
                date: 'today',
                state: '',
                category: '',
                product: ''
            },
            filterChanges: false,
            filterBy: 'tabOne',
            showBy: 'expiration_date',
            customDate: '',
            error: {
                customDate: false
            },
            key: 1,
            photosOfLabel: [],
            selectedPreview: 'label',
            showMore: false,
            selectedLabel: null,
            createdBy: false
        }
    },
    computed: {
        categories() {
            return this.$store.getters['label/getSortedCategories']
        },
        states() {
            return this.$store.getters['label/getLabelStates']()
        },
        labels() {
            const self = this
            var labels = []
            var raw = this.$store.getters['label/getLabelsPrinted'].labels
            for (var key in raw) {
                var label = raw[key]
                label.product = label.reference
                label.current_print_time = this.showBy == 'print_date' ? label.date : label.end_date
                labels.push(label)
            }

            return labels.sort(function (itemA, itemB) {
                const itemADate = new Date(self.showBy == 'print_date' ? itemA.date : itemA.end_date)
                const itemBDate = new Date(self.showBy == 'print_date' ? itemB.date : itemB.end_date)
                return self.showBy == 'print_date' ? (itemADate > itemBDate ? -1 : 1) : itemADate < itemBDate ? -1 : 1
            })
        },
        isSummaryPhoto() {
            return this.selectedLabel && this.selectedLabel.summary && this.selectedLabel.summary.photo
        },
        photos: {
            set(newValue) {
                this.photosOfLabel = newValue
            },
            get() {
                return this.photosOfLabel
            }
        },
        results() {
            return this.$store.getters['label/getLabelsPrinted'].results
        }
    },
    methods: {
        switchShowFilters() {
            this.showFilters = !this.showFilters
            if (this.showFilters) {
                this.filters.date = 'today'
                this.date = moment().format('YYYY-MM-DD')
                this.search()
            } else {
                this.filters.product = ''
            }
        },
        showMoreOrLess() {
            this.showMore = !this.showMore
        },
        switchFilter(type) {
            let self = this
            this.page = 1
            let typeText = type == 'tabOne' ? 'end_date' : 'print_date'
            this.showBy = typeText
            this.filterBy = type === 'tabOne' ? 'tabOne' : 'tabTwo'
            var params = {
                start_date: this.date,
                end_date: this.date,
                date: this.filters.date != 'custom' ? this.filters.date : this.customDate,
                category: this.filters.category,
                states: this.filters.state,
                name: this.filters.product,
                number_pages: this.page,
                number_items: this.num_items,
                sort_type: typeText,
                sortType: self.showBy == 'print_date' ? 'desc' : 'asc'
            }
            this.$overlay.loading()
            this.$store.dispatch('label/loadLabelsPrinted', params).then(function () {
                self.$overlay.hide()
                var scroll = {
                    container: self.$refs.list,
                    target: document.querySelectorAll('.elabel .list')[0],
                    offset: -400
                }
                self.$root.scrollTo(scroll)
                self.key++
            })
        },
        calendarState(show) {
            this.calendarIsVisible = show
        },
        formatDateForCalendarNavigation() {
            var date = this.date
            if (date == 'today') {
                return moment().format('YYYY-MM-DD')
            } else if (date == 'yesterday') {
                return moment().subtract(1, 'days').format('YYYY-MM-DD')
            } else if (date == 'week') {
                return moment().subtract(1, 'weeks').format('YYYY-MM-DD')
            } else if (date == 'month') {
                return moment().subtract(1, 'months').format('YYYY-MM-DD')
            } else if (date == 'quarter') {
                return moment().subtract(3, 'months').format('YYYY-MM-DD')
            } else {
                return moment(date).format('YYYY-MM-DD')
            }
        },
        load(refresh = true) {
            this.search()
            // If portrait, do not show filters expanded
            this.expanded = !['portrait'].includes(this.$mq)
            this.num_items = ['portrait'].includes(this.$mq) ? 20 : 3 * 10
        },
        toggle() {
            this.expanded = !this.expanded
        },
        cleanSearch() {
            this.filters.product = ''
        },
        page_prev() {
            this.page -= 1
            this.search()
        },
        page_next() {
            this.page += 1
            this.search()
        },
        changeDate(date) {
            this.date = date
        },

        changeDateCalendar(date) {
            this.date = date
            this.search()
        },

        search() {
            if (fnCheckConnection()) {
                this.offline = false
                var self = this
                if (!this.showFilters) {
                    this.filters.date = ''
                }
                if (this.showFilters) {
                    if (this.filters.date == 'custom') {
                        if (this.customDate == '') {
                            this.error.customDate = true
                            return
                        } else {
                            this.error.customDate = false
                            this.date = this.customDate
                        }
                    }
                    if (this.filters.product.length > 0 && this.filters.product.length < 3) {
                        // snackbar
                        return this.$snackbar.error({ closeable: true, message: i18n.t('label.filter.error') })
                    }
                }
                var params = {
                    start_date: this.date,
                    end_date: this.date,
                    date: this.filters.date != 'custom' ? this.filters.date : this.customDate,
                    category: this.filters.category,
                    states: this.filters.state,
                    name: this.filters.product,
                    number_pages: this.page,
                    number_items: this.num_items,
                    sort_type: this.showBy,
                    sortType: self.showBy == 'print_date' ? 'desc' : 'asc'
                }
                this.$overlay.loading()
                this.$store.dispatch('label/loadLabelsPrinted', params).then(function () {
                    self.$overlay.hide()
                    var scroll = {
                        container: self.$refs.list,
                        target: document.querySelectorAll('.elabel .list')[0],
                        offset: -400
                    }
                    self.key++
                    self.$root.scrollTo(scroll)
                })
                this.compareFilters.category = params.category
                this.compareFilters.state = params.states
                this.compareFilters.product = params.name
            } else {
                this.offline = true
            }
        },
        fnClosePreview() {
            this.preview = false
            this.selectedLabel = null
            this.key++
        },
        previewLabel(label) {
            //TODO: add photo label
            var self = this
            var reference = this.$store.getters['label/getLabel'](label.reference)
            var format = this.$store.getters['printer/getTemplates'](reference.states[label.state].format)
            var values = JSON.parse(format.data)
            log('--- previewLabel ---')
            log('label', label)
            log('reference', reference)
            log('format', format)
            this.selectedLabel = label

            if (label.preview) {
                try {
                    var params = JSON.parse(label.data)
                    this.preview = JSON.parse(label.preview)

                    var oLocation = this.$store.getters['loginUser/getLocalLocation']
                    params['account'] = oLocation.account_name
                    params['location'] = oLocation.name
                    params['quantity'] = label.quantity
                    params['city'] = oLocation.city
                    params['address'] = oLocation.address
                    params['loc_state'] = oLocation.state
                    params['cp'] = oLocation.cp

                    if (params && params.photo && params.photo.length > 0) {
                        this.photos = [...params.photo]
                    }

                    log(params)
                    log(this.preview)

                    for (var key in this.preview.fields) {
                        var field = this.preview.fields[key]
                        var value = typeof field.f_id !== 'undefined' ? values[field.f_id] : field.value
                        const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                        let m
                        let aux_value = value
                        while ((m = regex.exec(value)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++
                            }

                            // The result can be accessed through the `m`-variable.
                            m.forEach((match, groupIndex) => {
                                var m_index = match.replace(/[\{\}\[\]]/gi, '')
                                let name = this.$t('label.preview.' + m_index)
                                if (name.indexOf('label.preview.') != -1) {
                                    name = m_index
                                }
                                log('Match: ', match)
                                log('Index: ', m_index)
                                if (!Object.keys(params).includes(m_index)) {
                                    const regex2 = /(_d[0-9]|_day|_timestamp|_time)/gi
                                    let n
                                    while ((n = regex2.exec(m_index)) !== null) {
                                        // This is necessary to avoid infinite loops with zero-width matches
                                        if (n.m_index === regex2.lastIndex) {
                                            regex2.lastIndex++
                                        }

                                        // The result can be accessed through the `m`-variable.
                                        n.forEach((match2, groupIndex) => {
                                            var keyValue = m_index.replace(match2, '')
                                            if (Object.keys(params).includes(keyValue)) {
                                                var d = moment(params[keyValue], 'DD/MM/YYYY HH:mm')
                                                if (!d.isValid()) {
                                                    d = moment(parseInt(params[keyValue]) * 1000)
                                                }
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue + '_timestamp'] = parseInt(d.valueOf() / 1000).toString()
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            } else if (Object.keys(params).includes(keyValue + '_timestamp')) {
                                                var d = moment(parseInt(params[keyValue + '_timestamp']) * 1000)
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue] = d.format('DD/MM/YYYY HH:mm')
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            }
                                        })
                                    }

                                    if (!Object.keys(params).includes(m_index)) {
                                        params[m_index] = ''
                                    }
                                }

                                if (m_index != '' && Object.keys(params).includes(m_index)) {
                                    aux_value = aux_value.replace(match, params[m_index])
                                }
                            })
                        }
                        self.preview.fields[key].value = aux_value
                    }
                    this.$snackbar.close()

                    this.print_date = params.print_date
                    this.createdBy = params.employee_name
                } catch (e) {
                    this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
                }
            } else {
                this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
            }
        },
        selectPreview(preview) {
            this.selectedPreview = preview
        }
    },
    created() {
        this.load()
    },
    beforeRouteLeave(to, from, next) {
        this.$snackbar.close()
        this.$popup.close()
        next()
    },
    watch: {
        filters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.filters) != JSON.stringify(this.compareFilters)) {
                    this.filterChanges = true
                } else {
                    this.filterChanges = false
                }
            },
            deep: true
        },
        compareFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(this.filters) != JSON.stringify(this.compareFilters)) {
                    this.filterChanges = true
                } else {
                    this.filterChanges = false
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.elabel {
    padding: 10px;
    .full-preview {
        @include display-flex();
        @include justify-content();
        @include align-items(center);
        @include flex-direction(column);
        width: 100%;
        height: 100%;

        .preview-container {
            width: 100%;
            @include display-flex();
            @include justify-content(space-around);
            @include flex-direction(row);

            .gallery-box {
                @include display-flex();
                @include justify-content();
                @include align-items(center);
                @include flex-direction(column);
                gap: 16px;
                .main-image {
                    @include border-radius();
                    overflow: hidden;
                    width: 455px;
                    height: 518px;

                    &.label {
                        width: auto;
                        height: auto;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .thumb-img {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(center);
                    gap: 8px;

                    .images-boxes {
                        width: 80px;
                        height: 80px;
                        background: $main-dark;
                        @include border-radius();
                        position: relative;
                        overflow: hidden;
                        border: solid 1px $color-neutral-300;
                        cursor: pointer;
                        box-sizing: border-box;

                        &.selected {
                            border: solid 3px $color-primary-500;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        &.label {
                            &::after {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 60px;
                                height: 60px;
                                transform: translate(-50%, -50%);
                                @include background($image: img('menu_elabel_light.svg'), $position: center center);
                            }
                        }
                    }
                }
            }

            .summary-box {
                .info-summary {
                    width: 400px;
                    //@include default-box-shadow();
                    background: $color-white;
                    padding: 1rem 2rem;
                    @include border-radius(4px);
                    font-family: $text-sbold;
                    @include font-size(16px);
                    text-align: start;

                    .date {
                        font-family: $text-sbold;
                        text-align: start;
                    }
                }
            }
        }

        .btn-close {
            @include border-radius(4px);
            @include interaction;
            background-color: #fff;
            opacity: 0.7;
            margin-top: 25px;
            padding: 10px 20px;
            color: #444;
            font-family: $text-sbold;

            @include bgHover(darken(#fff, 5%));
        }

        .close-preview {
            position: absolute;
            @include background($image: img('minimize.svg'), $position: center center, $size: 20px, $color: #fff);
            @include border-radius(4px);
            @include interaction();
            width: 40px;
            height: 40px;
            top: 20px;
            right: 20px;
        }

        .date {
            @include font-size(s);
            font-family: $text-light;
            color: $neutro-s80;
            width: 100%;
            display: inline-block;
            bottom: 0px;
            padding: 10px 0;
            text-align: center;
        }
    }
    .header-container {
        width: 100%;
        height: 45px;
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);

        .traceability-selector {
            @include display-flex();
            @include justify-content(space-between);
            @include align-items(center);
            @include border-radius(4px);
            width: calc(100% - 50px);
            height: 45px;
            padding: 3px;
        }
        .search {
            @include background($image: img('search_neutro.svg'), $color: #fff, $size: 25px);
            @include border-radius(4px);
            height: 45px;
            width: 45px;
            cursor: pointer;

            &.selected {
                @include background($image: img('search_light.svg'), $color: $main-dark, $size: 25px);
            }
        }
    }
    .calendar-navigation-container {
        padding-top: 8px;
    }
    .filter-btn {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include background($image: img('filter_elabel.svg'), $position: left center, $size: 30px);
        @include font-size(sm);
        @extend .interaction;
        color: $neutro-s80;
        width: fit-content;
        font-family: $text-thin;
        height: 50px;
        padding: 20px 40px;
        margin: 0 auto;
        transition-duration: 0.2s;
    }

    .slide-fade-enter-active {
        transition: all 0.2s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.2s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(-50px);
        opacity: 0;
    }

    .filters {
        overflow: hidden;

        .item {
            @include border-radius(5px);
            padding: 0px 10px;
            width: 100%;
            border: none;
            padding-left: 50px;
            height: 45px;
            line-height: 45px;
            appearance: auto;
            display: inline-block;
            float: left;
            background-color: #fff;
            position: relative;
            cursor: pointer;
            font-family: $text-light;
            color: $neutro-s80;

            &.date {
                @include background($image: img('input_datetime_cccccc.svg'), $position: left 15px center, $size: 25px);
            }
            &.expand {
                width: 45px;
                background: #fff;
                padding: 0;
                @include background($image: img('down_dark.svg'), $position: center, $size: 25px);
                float: right;
                display: none;

                &.expanded {
                    @include background($image: img('up_dark.svg'), $position: center, $size: 25px);
                }
            }
            &.category {
                @include background($image: img('filter_neutro.svg'), $position: left 15px center, $size: 25px);
            }
            &.state {
                @include background($image: img('label_neutro.svg'), $position: left 15px center, $size: 25px);
            }
            &.product {
                @include background($image: img('search_neutro.svg'), $position: left 15px center, $size: 25px);

                input {
                    width: calc(100% - 20px);
                    float: left;
                    border: none;
                    padding: 0;
                    height: 45px;
                    margin: 0;
                    font-family: $text-light;
                    color: $neutro-s80;
                    cursor: text;
                    @include font-size(sm);
                }

                .close {
                    float: left;
                    @include border-radius(4px);
                    @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    background-color: $neutro-t50;
                    cursor: pointer;
                }
            }
            &.bttn {
                background: $main;
                text-align: center;
                color: #fff !important;
                padding: 0 10px;
                font-family: $text-bold !important;

                &.disabled {
                    background-color: $main-t80 !important;
                    cursor: not-allowed;
                    color: $main-t40 !important;
                    font-family: $text-bold !important;
                    pointer-events: none;
                }
            }
        }

        .advance {
            padding-top: 6px;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
            height: auto;
            grid-template-rows: 45px;
            width: 100%;
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;
        padding-top: 8px;

        @media (max-width: 1200px) {
            // grid-template-columns: 1fr 1fr;
        }

        &.empty {
            height: 100%;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

        .pagination {
            width: 100%;
            padding: 0px;
            display: block;
            grid-column: 1 / 4;

            .item {
                width: calc(50% - 10px);
                height: 45px;
                margin: 0 5px;
                float: left;
                @include border-radius(4px);

                &.next {
                    @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $main-t80);
                    float: right;
                }
                &.prev {
                    @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $main-t80);
                }
            }
        }
        &.filters-opened {
            height: calc(100% - 190px);
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }
    &.landscape {
    }
    &.desktop {
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.portrait #content.elabel {
    padding: 10px;
    .header-container {
        .traceability-selector {
            .text {
                @include font-size(s);
            }
        }
    }
    .full-preview {
        padding: 10px 0;
        padding-top: 70px;

        .date {
            // width: calc(100% - 40px);
            // display: inline-block;
            // position: absolute;
            // left: 20px;
            // top: 20px;
            // padding: 10px 0;
            // text-align: left;
        }

        .preview-container {
            @include flex-direction(column-reverse);
            @include justify-content(space-around);
            gap: 8px;
            height: 100%;

            .gallery-box {
                @include flex-direction(column);
                .main-image {
                    width: 370px;
                    height: 442px;

                    &.label {
                        width: auto;
                        height: auto;
                    }
                }

                .thumb-img {
                    @include display-flex();
                    @include justify-content();
                    @include align-items(center);
                    gap: 8px;

                    .images-boxes {
                        width: 60px;
                        height: 60px;

                        &.label {
                            &::after {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }
                }
            }

            .summary-box {
                .text-box {
                    max-height: 40px;
                    overflow: hidden;

                    &.expand {
                        max-height: unset;
                    }
                }
                .show-more-container {
                    @include display-flex();
                    @include justify-content(flex-end);
                    .show-more-less {
                        color: $color-neutral-600;
                        text-align: right;
                        cursor: pointer;
                        @include font-size(14px);
                    }
                }

                .info-summary {
                    width: auto;
                    padding: 16px;
                    @include border-radius(4px);
                    font-family: $text-sbold;
                    @include font-size(12px);
                    text-align: start;

                    .date {
                        font-family: $text-sbold;
                        text-align: start;
                        @include font-size(14px);
                    }
                }
            }
        }
    }
    .filter-btn {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include background($image: img('filter_elabel.svg'), $position: left center, $size: 30px);
        @include font-size(sm);
        @extend .interaction;
        color: $neutro-s80;
        width: fit-content;
        font-family: $text-light;
        height: 50px;
        padding: 20px 40px;
        margin: 0 auto;
        transition-duration: 0.2s;
    }

    .slide-fade-enter-active {
        transition: all 0.2s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.2s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(-50px);
        opacity: 0;
    }
    .filters {
        padding-top: 0px;

        .item {
            @include font-size(s);
            width: 100%;
            max-width: 100%;
            margin: 5px 0;
            font-family: $text;
            color: $neutro-s80;

            &.expand {
                width: 45px;
                display: block;
            }
        }
        .advance {
            display: block;
            width: 100%;
            margin-top: 10px;
            float: left;

            .date-input {
                @include border-radius(4px);
                @include font-size(s);
                width: 60%;
                height: 40px;
                padding: 0 5px;
                border: 1px solid $default-sec-color;
                color: $neutro-s80;
                font-family: $text;
                margin-right: 6px;

                &:focus {
                    outline: none;
                    border: 1px solid $default-sec-color;
                }

                &::placeholder {
                    color: $neutro-s80;
                }

                &.error {
                    border: 1px solid $error_dark;
                }
            }
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;

        &::-webkit-scrollbar {
            display: none;
        }

        &.expanded {
            height: calc(100% - 130px);
        }
        &.filters-opened {
            height: calc(100% - 355px);

            &.calendarOpened {
                height: calc(100% - 745px);
            }
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }

    .pagination {
        width: 100%;
        padding: 0px;
        grid-column: 1;

        .item {
            width: calc(50% - 10px);

            &.next {
            }
            &.prev {
            }
        }
    }
}

.landscape #content.elabel {
    padding: 10px;
    .full-preview {
        padding: 10px 0;
        padding-top: 70px;

        .date {
            // width: calc(100% - 40px);
            // display: inline-block;
            // position: absolute;
            // left: 20px;
            // top: 20px;
            // padding: 10px 0;
            // text-align: left;
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;

        &.empty {
            grid-template-columns: 1fr;
        }

        &::-webkit-scrollbar {
            display: none;
        }
        &.filters-opened {
            height: calc(100% - 190px);

            &.calendarOpened {
                height: calc(100% - 560px);
            }
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }

    .pagination {
        width: 100%;
        padding: 0px;
        grid-column: 1/3;

        .item {
            width: calc(50% - 10px);

            &.next {
            }
            &.prev {
            }
        }
    }
}
</style>
