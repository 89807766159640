<template>
    <div class="add-image" v-if="!labelData.result || labelData.result.length == 0" :class="$mq">
        <p class="info-text">{{ $t('tickets.photo_label_msg') }}</p>
        <div class="box-images">
            <div class="images" @click="capture($event, 0)">
                <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload($event, 0)" />
            </div>
            <div class="images" @click="capture($event, 1)">
                <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload($event, 1)" />
            </div>
            <div class="images" @click="capture($event, 2)">
                <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload($event, 2)" />
            </div>
            <div class="images" @click="capture($event, 3)">
                <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload($event, 3)" />
            </div>
        </div>
    </div>
    <div class="add-image" v-else :class="$mq">
        <div class="main-image" @click="deleteImage" v-if="labelData.result.length > 0 && selectedPreview" :class="{'preview': selectedPreview == 'label'}">
            <Preview :data="previewData" :maximized="true" v-if="selectedPreview == 'label' && $mq.includes('portrait')"></Preview>
            <img :src="selectedPreview" v-else/>
        </div>
        <div class="box-images">
            <div class="images-empty label" @click="selectPreview('label')" :class="{'selected': 'label' == selectedPreview}" v-if="$mq.includes('portrait')">
            </div>
            <div class="images-empty" v-for="(image, index) in 4" :key="index" :class="{'photo': labelData.result[index], 'selected': labelData.result[index] == selectedPreview}">
                <img v-if="labelData.result[index]" :src="labelData.result[index]" @click="selectPreview(index)"/>
                <div class="resync" @click="resyncImage(index)" v-if="labelData.result[index] && !uploading[index] && !/^(https?:\/\/)/.test(labelData.result[index])"></div>
                <div class="add-button" @click="capture($event, index)">
                    <input type="file" name="change-image" class="input-file" accept="image/*" capture="camera" @change="imageReload($event, index)" />
                </div>
            </div>
        </div>
        <span class="delete-icon" @click="deleteImage" v-if="selectedPreview && /^(https?:\/\/)/.test(selectedPreview)"></span>
    </div>
</template>

<script>
import Preview from '@/components/domain/label/preview.vue'
export default {
    components: {
        Preview
    },
    props: {
        data: { type: Object },
        productId: { type: String },
        previewData: { type: Object }
    },
    computed: {
        labelData() {
            return this.data;
        }
    },
    data(){
        return {
            selectedPreview: false,
            uploading: false
        }
    },
    methods: {
        capture(e, index) {
            const isOfflineActive = this.$store.getters.getOffline
            log('PASO 1')
            if (fnCheckConnection() || isOfflineActive) {
                log('PASO 2')
                if (process.env.VUE_APP_IS_APP == 'TRUE') {
                    var self = this
                    log('PASO 3')
                    e.preventDefault()
                    e.stopPropagation()
                    var self = this

                    var cameraOptions = {
                        destinationType: 1,
                        quality: 50,
                        encodingType: 0, // JEPG
                        targetWidth: andyImageLimit.width,
                        targetHeight: andyImageLimit.height,
                        correctOrientation: true
                    }
                    //LIMITE DE FOTOS
                    const limitOfImages = this.labelData.maxPhoto
                    const valuesOfImages = this.labelData.result ? this.labelData.result : []
                    const rules = this.labelData.rules ? this.labelData.rules : false

                    if (valuesOfImages.lenght > 0 && valuesOfImages.lenght === limitOfImages) {
                        this.$popup.alert({ message: this.$t('answer.galery.message') })
                    } else {
                        if (!rules || !rules.type || rules.type === 'all') {
                            this.$popup.confirmUpload({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSaveStart: this.$t('cordova.plugins.camera.text_camera'),
                                textSave: this.$t('cordova.plugins.camera.text_library'),
                                callSaveStart: function () {
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNative(index)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    self.loadImageAndPush(bgImg.src, index)
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                // function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                },
                                callSave: function () {
                                    cameraOptions.sourceType = 0
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNativeGallery(index)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    self.loadImageAndPush(bgImg.src, index)
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                // function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        } else if (rules.type == 'camera') {
                            this.$popup.confirm({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSave: this.$t('cordova.plugins.camera.text_camera'),
                                callSave: function () {
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNative(index)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        getFileContentAsBase64(
                                                            imageData,
                                                            function (base64Image) {
                                                                // Then you'll be able to handle the myimage.png file as base64
                                                                log(base64Image)
                                                                imageData = base64Image

                                                                var bgImg = new Image()
                                                                bgImg.onload = function () {
                                                                    //Subida del archivo
                                                                    let aux = self.value
                                                                    if (!aux) {
                                                                        aux = []
                                                                    }
                                                                    aux.push(bgImg.src)
                                                                    console.log('Se ha subido tal>> ', JSON.parse(JSON.stringify(aux)))
                                                                }
                                                                bgImg.src = imageData
                                                            },
                                                            function (err) {
                                                                self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                                                            }
                                                        )
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                // function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        } else if (rules.type == 'gallery') {
                            this.$popup.confirm({
                                message: this.$t('cordova.plugins.camera.title'),
                                textSave: this.$t('cordova.plugins.camera.text_library'),
                                callSave: function () {
                                    cameraOptions.sourceType = 0
                                    if (fnCheckConnection() || isOfflineActive) {
                                        if (self.$store.getters.getIsAndroid) {
                                            self.captureNativeGallery(index)
                                        } else {
                                            navigator.camera.getPicture(
                                                function (imageData) {
                                                    if (fnCheckConnection()) {
                                                        imageData = 'data:image/jpeg;base64,' + imageData
                                                        var bgImg = new Image()
                                                        bgImg.onload = function () {
                                                            self.loadImageAndPush(bgImg.src, index)
                                                        }
                                                        bgImg.src = imageData
                                                    } else {
                                                        this.$snackbar.error({
                                                            duration: 5000,
                                                            closeable: true,
                                                            message: this.$t('empty_pages.connection_description')
                                                        })
                                                    }
                                                },
                                                // function (message) {},
                                                cameraOptions
                                            )
                                        }
                                    } else {
                                        this.$snackbar.error({
                                            duration: 5000,
                                            closeable: true,
                                            message: this.$t('empty_pages.connection_description')
                                        })
                                    }
                                }
                            })
                        }
                    }
                }
            } else if (!isOfflineActive) {
                e.preventDefault()
                e.stopPropagation()
                this.$snackbar.error({ duration: 5000, closeable: true, message: this.$t('empty_pages.connection_description') })
            }
        },
        captureNativeGallery(index) {
            var self = this
            var input = document.createElement('input')
            input.type = 'file'
            input.accept = 'image/*'
            input.capture = 'camera'

            input.onchange = function (ev) {
                const file = ev.target.files[0] // get the file
                const blobURL = URL.createObjectURL(file)
                const img = new Image()
                img.src = blobURL
                img.onerror = function () {
                    URL.revokeObjectURL(this.src)
                    // Handle the failure properly
                    self.$snackbar.error({ closeable: true, message: self.$t('popup.media.image_empty') })
                }
                img.onload = function () {
                    URL.revokeObjectURL(this.src)
                    const [newWidth, newHeight] = calculateImgSize(img, andyImageLimit.width, andyImageLimit.height)
                    const canvas = document.createElement('canvas')
                    canvas.width = newWidth
                    canvas.height = newHeight
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, newWidth, newHeight)
                    canvas.toBlob(
                        (blob) => {
                            // Handle the compressed image. es. upload or save in local state
                            displayFileInfo('Original file', file)
                            displayFileInfo('Compressed file', blob)
                            var reader = new FileReader()
                            reader.readAsDataURL(blob)
                            reader.onloadend = function () {
                                var base64data = reader.result
                                self.loadImageAndPush(base64data, index) 
                            }
                        },
                        andyImageLimit.mime,
                        andyImageLimit.quality
                    )
                }
            }

            input.click()
        },
        captureNative(index) {
            var self = this
            this.$overlay.loading()
            this.$store.commit('setCameraActive', true)
            var imageCapture
            var track
            var currentCamera = 'back'
            var flash = false

            setTimeout(function () {
                navigator.mediaDevices
                    .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                    .then((mediaStream) => {
                        track = mediaStream.getVideoTracks()[0]
                        imageCapture = new ImageCapture(track)
                        document.querySelector('video').srcObject = mediaStream
                    })
                    .catch((error) => console.error(error))

                document.getElementById('swap-camera').addEventListener('click', function (e) {
                    log('swap camera')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()

                    if (currentCamera == 'front') {
                        navigator.mediaDevices
                            .getUserMedia({ video: { facingMode: { exact: 'environment' } } })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.error(error))
                    } else {
                        if (flash) {
                            track.applyConstraints({
                                advanced: [{ torch: !flash }]
                            })
                            flash = !flash
                            document.getElementById('flash-camera').classList.remove('bounce')
                        }

                        navigator.mediaDevices
                            .getUserMedia({ video: true })
                            .then((mediaStream) => {
                                track = mediaStream.getVideoTracks()[0]
                                imageCapture = new ImageCapture(track)
                                document.querySelector('video').srcObject = mediaStream
                            })
                            .catch((error) => console.error(error))
                    }

                    currentCamera = currentCamera == 'back' ? 'front' : 'back'
                })

                document.getElementById('flash-camera').addEventListener('click', function (e) {
                    log('flash photo')
                    e.preventDefault()
                    e.stopPropagation()

                    if (currentCamera == 'back') {
                        track.applyConstraints({
                            advanced: [{ torch: !flash }]
                        })
                        flash = !flash
                        if (flash) {
                            document.getElementById('flash-camera').classList.remove('bounce')
                        } else {
                            document.getElementById('flash-camera').classList.add('bounce')
                        }
                    }
                })

                document.getElementById('cancel-camera').addEventListener('click', function (e) {
                    log('cancel photo')
                    e.preventDefault()
                    e.stopPropagation()

                    track.stop()
                    self.$store.commit('setCameraActive', false)
                })

                document.getElementById('take-camera').addEventListener('click', function (e) {
                    log('take photo')
                    e.preventDefault()
                    e.stopPropagation()

                    imageCapture
                        .takePhoto()
                        .then((blob) => createImageBitmap(blob))
                        .then((imageBitmap) => {
                            const [newWidth, newHeight] = calculateImgSize(imageBitmap, andyImageLimit.width, andyImageLimit.height)
                            const canvas = document.createElement('canvas')
                            canvas.width = newWidth
                            canvas.height = newHeight
                            const ctx = canvas.getContext('2d')
                            ctx.drawImage(imageBitmap, 0, 0, newWidth, newHeight)
                            canvas.toBlob(
                                (blob) => {
                                    // Handle the compressed image. es. upload or save in local state
                                    // displayFileInfo('Original file', file)
                                    displayFileInfo('Compressed file', blob)
                                    var reader = new FileReader()
                                    reader.readAsDataURL(blob)
                                    reader.onloadend = function () {
                                        var base64data = reader.result
                                        log('base64 image:', base64data) 
                                        self.loadImageAndPush(base64data, index) 

                                        self.$store.commit('setCameraActive', false)
                                        track.stop()
                                    }
                                },
                                andyImageLimit.mime,
                                andyImageLimit.quality
                            )

                            // drawCanvas(canvas, imageBitmap)
                        })
                        .catch((error) => console.error(error))
                })

                self.$overlay.hide()
            }, 200)
        },
        imageReload(e, index) {
            var self = this
            const files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            const reader = new FileReader()
            reader.onerror = (error) => {
                console.error('Error reading file:', error)
            }

            reader.onload = (e) => {
                // this.image = e.target.result

                const image = new Image()

                image.onload = () => {
                    //Subir la imagen? a la api
                    const imageResized = this.resizeImage(image)
                    self.loadImageAndPush(imageResized, index)
                }

                image.src = e.target.result
            }

            reader.readAsDataURL(files[0])
        },
        resizeImage(image) {
            const maxWidth = andyImageLimit.width
            const maxHeight = andyImageLimit.height

            let newWidth, newHeight

            if (image.width > image.height) {
                newWidth = maxWidth
                newHeight = (image.height / image.width) * maxWidth
            } else {
                newHeight = maxHeight
                newWidth = (image.width / image.height) * maxHeight
            }

            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            canvas.width = newWidth
            canvas.height = newHeight

            ctx.drawImage(image, 0, 0, newWidth, newHeight)

            const newImage = canvas.toDataURL('image/jpeg', 0.7)

            return newImage
        },
        loadImageAndPush(image, index){
            if (!Array.isArray(this.labelData.result)) {
                this.$set(this.labelData, 'result', []);
            }
            this.$set(this.labelData, 'result', [...this.labelData.result, image]);
            if(this.labelData.result.length == 1){
                this.selectedPreview = this.labelData.result[0];
            }
            this.uploading = { ...this.uploading, [index]: true } 
            this.$store.dispatch('label/uploadLabelImages', { file: image, id: this.productId }).then((response) => {
                let selectedIndex = this.labelData.result.findIndex((e)=>e == this.selectedPreview)
                const newResults = [...this.labelData.result];
                newResults.pop();
                newResults.push(response.file);
                this.$set(this.labelData, 'result', newResults);
                this.selectedPreview = this.labelData.result[selectedIndex];
                this.uploading = { ...this.uploading, [index]: false } 
            }).catch((error) => {
                this.uploading = { ...this.uploading, [index]: false } 
            })
        },
        resyncImage(index){
                this.uploading = { ...this.uploading, [index]: true } 
            this.$store.dispatch('label/uploadLabelImages', { file: this.labelData.result[index], id: this.productId }).then((response) => {
                this.uploading = { ...this.uploading, [index]: false } 
                this.labelData.result[index] = response.file;
            })
        },
        selectPreview(index) {
            if(index != 'label'){
                this.selectedPreview = this.labelData.result[index];
            }else{
                this.selectedPreview = 'label';
            }
        },
        deleteImage() {
            if (!this.selectedPreview) return;
            var self = this
            this.$popup.media({
                image: this.selectedPreview,
                textCancel: self.$t('menu_aux.action.cancel'),
                textDelete: self.$t('menu_aux.action.delete'),
                callCancel: function () {
                    this.$popup.close();
                },
                callDelete: function () {
                    self.$store.dispatch('library/deleteImage', {url: self.selectedPreview})
                    const index = self.labelData.result.indexOf(self.selectedPreview);
                    if (index !== -1) {
                        self.labelData.result.splice(index, 1);
                        self.$forceUpdate();
                    }
                    
                    if (self.labelData.result.length > 0) {
                        self.selectedPreview = self.labelData.result[0];
                    } else {
                        self.selectedPreview = null;
                    }
                    self.$popup.close()
                }
            })
        }
    },
    mounted(){
        if(this.labelData.result.length == 1){
            this.selectedPreview = this.labelData.result[0];
        }
    },
    watch: {
        labelData: {
            handler(newValue) {
                this.$emit('update-data', newValue)
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.add-image {
    @include display-flex();
    @include justify-content();
    @include align-items(center);
    @include flex-direction(column);
    gap: 30px;

    .add-button {
        width: 80px;
        height: 70px;
        background: $main-dark;
        position: relative;
        cursor: pointer;
        @include border-radius();


        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50px;
            height: 50px;
            transform: translate(-50%, -50%);
            @include background($image: img('camera_light.svg'), $position: center center);
            z-index: 1;
        }
    }

    .info-text {
        font-family: $text-light;
        color: $color-neutral-600;
    }
    .main-image {
        width: 200px;
        height: 200px;
        @include border-radius();
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .box-images {
        width: 100%;
        height: 70px;
        @include display-flex();
        @include justify-content();
        @include align-items(center);
        gap: 8px;

        .images {
            width: 70px;
            height: 70px;
            background: #d9d9d9;
            @include border-radius();
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 40px;
                height: 40px;
                transform: translate(-50%, -50%);
                @include background($image: img('camera_light.svg'), $position: center center);
            }

            .input-file {
                width: 100%;
                height: 100%;
                position: absolute;
                background: none;
                outline: none;
                border: none;
                opacity: 0;
                z-index: 3;

                &::file-selector-button {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: red;
                    top: 50%;
                    left: 50%;
                    outline: none;
                    border: none;
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }
        }

        .images-empty {
            width: 70px;
            height: 70px;
            background: #d9d9d9;
            @include border-radius();
            position: relative;
            overflow: hidden;
            border: solid 1px $color-neutral-300;
            cursor: pointer;

            &.photo {
                cursor: default;
                &::after {
                    display: none;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .resync{
                width: 70px;
                height: 70px;
                position: absolute;
                z-index: 3;
                cursor: pointer;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include background($image: img('/offline/resync.svg'), $size: 40px, $position: center center, $color: #0000004a);
            }

            &.selected{
                border: solid 3px $color-primary-500;
            }

            .add-button{
                width: 100%;
                height: 100%;
                background: #d9d9d9;
                position: relative;
                cursor: pointer;
                @include border-radius();


                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 40px;
                    height: 40px;
                    transform: translate(-50%, -50%);
                    @include background($image: img('camera_light.svg'), $position: center center);
                    z-index: 1;
                }

                .input-file {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: none;
                    outline: none;
                    border: none;
                    opacity: 0;
                    z-index: 3;
                    
                    &::file-selector-button {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        background: red;
                        top: 50%;
                        left: 50%;
                        outline: none;
                        border: none;
                        transform: translate(-50%, -50%);
                        cursor: pointer;
                        z-index: 5;
                    }
                }
            }
        }
    }

    .delete-icon {
        width: 35px;
        height: 35px;
        cursor: pointer;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            @include background($image: img('delete_neutral_500.svg'), $position: center center);
        }
    }
}
</style>

<!-- PORTRAIT -->
<style lang="scss" scoped>
.add-image {
    &.portrait {
        .info-text {
            @include font-size(16px);
            text-align: center;
        }
        
        .main-image{
            width: 360px;
            height: 420px;
            &.preview{
                width: auto;
                height: auto;
            }
        }
        .box-images {
            .images-empty {
                &.label{
                    background:$main-dark;
                    cursor: default;
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 40px;
                        height: 40px;
                        transform: translate(-50%, -50%);
                        @include background($image: img('menu_label_light.svg'), $position: center center);
                    }
                }
            }
        }
    }
}
</style>


