<template>
    <div class="label-box-container" :class="$mq">
        <!-- :class="labelClass(label)" -->
        <div class="label">
            <span
                class="color"
                v-if="showBall"
                v-bind:style="{
                    backgroundColor: categories[label.category_id].color
                }"
            ></span>

            <div v-if="!isApp" class="image">
                <!-- <cld-image class="image-cloud" :publicId="label.image ? label.image : apiURL + 'static/images/offline/no_icon_light.svg'" type="fetch" loading="lazy">
                    <cld-transformation height="100" crop="fill" fetchFormat="webp" />
                </cld-image> -->
                <img :src="label.image ? label.image : appURL + 'img/offline/no_icon_light.svg'"/>
            </div>
            <div
                v-else
                class="imageapp"
                :class="{ appempty: !label.local_image && !label.image }"
                :style="{
                    backgroundImage: 'url(' + (label.local_image ? label.local_image : (label.image ? label.image : appURL + 'img/offline/no_icon_light.svg')) + ')'
                }"
            ></div>

            <div class="label-text">{{ label.name }}</div>
        </div>
        <p v-if="!label" class="empty">
            {{ $t('label.empty_labels') }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'LabelBox',
    props: {
        label: {
            type: undefined,
            default: false
        },
        show: {
            type: Boolean,
            default: false
        },
        showBall: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            // label: this.label
        }
    },
    methods: {},
    computed: {
        //
        categories() {
            return this.$store.getters['label/getCategories']
        },
        appURL() {
            return process.env.VUE_APP_APP_URL
        },
        isApp() {
            var bIsApp = process.env.VUE_APP_IS_APP == 'TRUE'
            return bIsApp
        }
    }
}
</script>

<style lang="scss">
$icon-size: rem(60px);

.label-box-container {
    width: 100%;
    height: 100%;
    max-height: 145px;
    min-height: 145px;

    .label {
        @include border-radius(5px);
        @include display-flex();
        @include flex-direction(column);
        background-color: #fff;
        width: 100%;
        height: 100%;
        padding: 5px;
        position: relative;

        .color {
            @include border-radius(10px);
            width: 18px;
            height: 18px;
            position: absolute;
            top: 5px;
            left: 5px;
        }

        .image {
            display: block;
            margin: 10px auto;
            text-align: center;
            height: 60%;
            margin-top: 3px;
            padding: 15px;

            img {
                height: 100%;
                object-fit: contain;
            }
        }

        .label-text {
            @include font-size(m);
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            font-family: $text-bold;
            text-align: center;
            color: $main-s90;
            width: 100%;
            height: 30%;
            line-height: auto;
        }
        .imageapp {
            @include background($image: img('offline/no_icon_light.svg'));
            display: block;
            margin: 10px auto;
            text-align: center;
            height: 60%;
            margin-top: 3px;
            padding: 15px;
            width: 60%;
            img {
                height: 100%;
                object-fit: contain;
            }

            &.appempty {
                @include background($image: img('offline/no_icon_light.svg') !important);
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.label-box-container.portrait {
    .label-text {
        @include font-size(s);
        line-height: 0.9rem;
    }
}
</style>
